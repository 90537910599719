import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fetchSlides, fetchConfiguracoes } from "../../utils/dados";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";

// Defina um tipo para as imagens
interface Image {
    id: number;
    imagem: string;
    imagem_mobile: string;
    titulo: string;
    link: string;
}

interface ARadioData {
    link_admin: string;
}

const SlideShow: React.FC = () => {
    const [configuracoes, setConfiguracoes] = useState<ARadioData>();
    const [imagens, setImagens] = useState<Image[]>();
    const [currentImage, setCurrentImage] = useState(0);
    const [isPaused, setIsPaused] = useState(false);

    const goToPrevious = () => {
        if (imagens) {
            setCurrentImage((prevImage) => (prevImage === 0 ? imagens.length - 1 : prevImage - 1));
        }
    };

    const goToNext = () => {
        if (imagens) {
            setCurrentImage((prevImage) => (prevImage === imagens.length - 1 ? 0 : prevImage + 1));
        }
    };

    const pauseSlideShow = () => {
        setIsPaused(true);
    };

    const resumeSlideShow = () => {
        setIsPaused(false);
    };

    const chooseImageSource = () => {
        // Verifica se o array de imagens existe e tem pelo menos uma imagem
        if (imagens && imagens.length > 0) {
            // Verifica o tamanho da tela
            const isMobile = window.innerWidth <= 768; // Por exemplo, considerando 768px como ponto de corte para dispositivos móveis

            // Escolhe a fonte da imagem com base no tamanho da tela
            return isMobile
                ? `${configuracoes?.link_admin}/uploads/${imagens[currentImage].imagem_mobile}`
                : `${configuracoes?.link_admin}/uploads/${imagens[currentImage].imagem}`;
        }

        // Retorna uma string vazia se não houver imagens ou o array de imagens ainda não foi carregado
        return "";
    };

    useEffect(() => {
        async function fetchData() {
            const imagens = await fetchSlides(4);
            setImagens(imagens);

            const configuracoes = await fetchConfiguracoes();
            setConfiguracoes(configuracoes);
        }

        fetchData();
    }, []); // Apenas uma chamada à API quando o componente é montado

    useEffect(() => {
        let interval: NodeJS.Timeout;

        if (!isPaused && imagens) {
            interval = setInterval(goToNext, 5000);
        }

        return () => clearInterval(interval);
    }, [isPaused, imagens]);

    return (
        <div className="slideshow" onMouseEnter={pauseSlideShow} onMouseLeave={resumeSlideShow}>
            <div className="control control-icon-left" onClick={goToPrevious}>
                <FontAwesomeIcon icon={faChevronLeft} className="control-icon" />
            </div>
            <img className="fade" src={chooseImageSource()} alt={imagens && imagens[currentImage]?.titulo} />
            <div className="control control-icon-right" onClick={goToNext}>
                <FontAwesomeIcon icon={faChevronRight} className="control-icon" />
            </div>
        </div>
    );
};

export default SlideShow;
