import React from "react";
import Layout from "./../Layout";
import Noticias from "../features/Noticias";
import Header from "../features/Header";

const NoticiasPage: React.FC = () => {
    return (
        <>
            <Header
                title="Notícias"
                description="Confira as novidades sobre temas que envolvem o seu dia a dia. Fique por dentro de tudo que acontece no mundo da tecnologia, saúde, educação, entretenimento e muito mais"
                image=""
                type="website"
                type_twitter="summary"
                url={"noticias"}
            />
            <Layout>
                {/* Conteúdo da página */}
                <div className="container">
                    <div className="content-container">
                        <h1>Notícias</h1>
                        <Noticias paginacao={true} postPerPage={16} subtitulo={true} />
                    </div>
                </div>
            </Layout>
        </>
    );
};

export default NoticiasPage;
