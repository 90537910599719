import React, { useEffect } from "react";

interface AdSenseAdProps {
    client: string; // ID do cliente AdSense
    slot: string; // ID do slot de anúncio
    format?: string;
    style?: React.CSSProperties; // Propriedades de estilo opcionais
}

const AdSenseAd: React.FC<AdSenseAdProps> = ({ client, slot, format = "auto", style = { display: "block" } }) => {
    useEffect(() => {
        setTimeout(() => {
            try {
                // @ts-ignore
                (window.adsbygoogle = window.adsbygoogle || []).push({});
            } catch (e) {
                console.error("Erro ao carregar o anúncio do AdSense:", e);
            }
        }, 1000); // Atrasa a carga por 1 segundo, ajuste conforme necessário
    }, []);

    return <ins className="adsbygoogle" style={style} data-ad-client={client} data-ad-slot={slot} data-ad-format={format}></ins>;
};

export default AdSenseAd;
