import axios from "axios";

const servidor = "https://www.89fmonline.com.br/api";

// Função para buscar os itens do menu da API
export async function fetchConfiguracoes() {
    try {
        const response = await axios.get(`${servidor}/configuracoes.json`);
        return response.data;
    } catch (error) {
        console.error("Erro ao buscar configurações:", error);
        return [];
    }
}

export async function fetchNoticias(pageNumber: number, postPerPage: number) {
    try {
        const response = await axios.get(`${servidor}/postagens.json/${pageNumber}/${postPerPage}`);
        return response.data;
    } catch (error) {
        console.error("Erro ao buscar notícias:", error);
        return [];
    }
}

export async function fetchNoticia(slug: any) {
    try {
        const response = await axios.get(`${servidor}/postagem.json/${slug}`);
        return response.data;
    } catch (error) {
        console.error("Erro ao buscar notícia:", error);
        return [];
    }
}

export async function fetchProgramacao(day: any) {
    try {
        const response = await axios.get(`${servidor}/programacao.json/${day}`);
        return response.data;
    } catch (error) {
        console.error("Erro ao buscar programação:", error);
        return [];
    }
}

export async function fetchProgramaAtual(day: any, hora: any) {
    try {
        const response = await axios.get(`${servidor}/programacao.json/${day}/${hora}`);
        return response.data;
    } catch (error) {
        console.error("Erro ao buscar programação:", error);
        return [];
    }
}

export async function fetchSlides(tipo: any) {
    try {
        const response = await axios.get(`${servidor}/slides.json/${tipo}`);
        return response.data;
    } catch (error) {
        console.error("Erro ao buscar slides:", error);
        return [];
    }
}
