import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useAudio } from "./AudioContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay, faPause, faVolumeUp } from "@fortawesome/free-solid-svg-icons";
import { fetchProgramaAtual, fetchConfiguracoes } from "../../utils/dados";

import "./../../assets/css/player.css";

interface ProgramaData {
    id: number;
    slug: string;
    programa: string;
    imagem: string;
    h_seg: string;
    h_ter: string;
    h_qua: string;
    h_qui: string;
    h_sex: string;
    h_sab: string;
    h_dom: string;
    h_seg_fim: string;
    h_ter_fim: string;
    h_qua_fim: string;
    h_qui_fim: string;
    h_sex_fim: string;
    h_sab_fim: string;
    h_dom_fim: string;
}

interface diaHorario {
    horario: string;
    horario_fim: string;
}

interface ARadioData {
    link_admin: string;
}

const AudioPlayer = () => {
    const [ProgramaData, setProgramasData] = useState<ProgramaData>();
    const [diaHorario, setDiaHorario] = useState<diaHorario>();
    const { isPlaying, togglePlay, volume, setVolume } = useAudio();
    const [configuracoes, setConfiguracoes] = useState<ARadioData>();

    const getDayOfWeek = () => {
        const daysOfWeek = ["dom", "seg", "ter", "qua", "qui", "sex", "sab"];
        const today = new Date().getDay(); // Obtém o dia da semana (0 a 6, onde 0 é domingo)
        return daysOfWeek[today];
    };

    const getCurrentTime = () => {
        const now = new Date();
        const hours = String(now.getHours()).padStart(2, "0"); // Obtém as horas no formato HH
        const minutes = String(now.getMinutes()).padStart(2, "0"); // Obtém os minutos no formato ii
        return `${hours}:${minutes}`;
    };

    const getDiaHorario = (programa: any, dia: any) => {
        let horario_dia = {
            horario: "",
            horario_fim: "",
        };

        if (programa) {
            if (horario_dia.horario === "") {
                horario_dia.horario = programa[`h_${dia}`];
                horario_dia.horario_fim = programa[`h_${dia}_fim`];
            }
        }

        return horario_dia;
    };

    const fetchInfoByDay = async (day: string, hora: string) => {
        const programas = await fetchProgramaAtual(day, hora);
        setProgramasData(programas[0]);
        const diaHorario = getDiaHorario(programas[0], day);
        setDiaHorario(diaHorario);
        console.log(programas[0]);
    };

    useEffect(() => {
        //togglePlay(); // Inicie a reprodução ao carregar a página

        async function fetchData() {
            const configuracoes = await fetchConfiguracoes();
            setConfiguracoes(configuracoes);
        }

        fetchData();

        const today = getDayOfWeek();
        const currentTime = getCurrentTime();
        fetchInfoByDay(today, currentTime);

        // Configurar a repetição a cada 1 minuto (60,000 milissegundos)
        const intervalId = setInterval(() => {
            const today = getDayOfWeek();
            const currentTime = getCurrentTime();
            fetchInfoByDay(today, currentTime);
        }, 60000);

        // Certifique-se de limpar o intervalo quando o componente é desmontado
        return () => {
            clearInterval(intervalId);
        };
    }, []);

    const handleVolumeChange = (newVolume: number) => {
        setVolume(newVolume);
    };

    return (
        <div className="audio-player-container">
            <div className="audio-player-descricao">
                <div
                    className="audio-player-image"
                    style={{ backgroundImage: `url(${configuracoes?.link_admin}/uploads/${ProgramaData?.imagem.replace(/&quot;/g, '"')})` }}
                ></div>
                <div className="audio-player-titulo">
                    <span className="programa-atual">{ProgramaData?.programa ? ProgramaData?.programa : "Horário Livre"}</span>
                    <span className="programa-horario">{diaHorario?.horario ? `${diaHorario?.horario} às ${diaHorario?.horario_fim}` : ""}</span>
                </div>
            </div>
            <div className="audio-player-control">
                <button onClick={togglePlay}>
                    {isPlaying ? <FontAwesomeIcon icon={faPause} className="icone-control" /> : <FontAwesomeIcon icon={faPlay} className="icone-control" />}
                </button>
            </div>
            <div className="audio-player-volume">
                <FontAwesomeIcon icon={faVolumeUp} className="icone-volume" />
                <input
                    type="range"
                    min="0"
                    max="1"
                    step="0.1"
                    className="thin-range"
                    value={volume}
                    onChange={(e) => handleVolumeChange(parseFloat(e.target.value))}
                />
            </div>
        </div>
    );
};

export default AudioPlayer;
