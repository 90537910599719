import React from "react";
import Layout from "./../Layout";

const EquipePage: React.FC = () => {
    return (
        <Layout>
            {/* Conteúdo da página */}
            <h1>Equipe</h1>
            <p>Equipe...</p>
        </Layout>
    );
};

export default EquipePage;
