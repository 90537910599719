import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { fetchConfiguracoes } from "../../utils/dados";

interface HeaderProps {
    title: string;
    description: string;
    image: string;
    url: string;
    type: string;
    type_twitter: string;
}

interface ARadioData {
    link: string;
}

const Header = ({ title, description = "", image = "", url = "", type = "", type_twitter = "" }: HeaderProps) => {
    const [configuracoes, setConfiguracoes] = useState<ARadioData>();

    useEffect(() => {
        async function fetchData() {
            const configuracoes = await fetchConfiguracoes();
            setConfiguracoes(configuracoes);
        }

        fetchData();
    }, []);

    return (
        <Helmet>
            {/* GERAL */}
            <title>{title}</title>

            {/* OPEN GRAPH */}
            <meta property="og:title" content="89FM Online" />
            <meta property="og:type" content={type} />
            <meta property="og:description" content={description} />
            <meta property="og:image" content={image} />
            <meta property="og:url" content={`${configuracoes?.link}/${url}`} />
            <meta property="og:site_name" content="Marketing de Conteúdo" />
            <meta property="fb:admins" content="" />

            {/* TWITTER */}
            <meta name="twitter:card" content={type_twitter} />
            <meta name="twitter:url" content={`${configuracoes?.link}/${url}`} />
            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={description} />
            <meta name="twitter:image" content={image} />
        </Helmet>
    );
};

export default Header;
