import React, { useEffect, useState } from "react";
import Layout from "./../Layout";
import Noticias from "../features/Noticias";
import { Link } from "react-router-dom";
import Programas from "../features/Programas";
import Contato from "../features/Contato";
import { fetchConfiguracoes } from "../../utils/dados";
import { limitarParaParagrafos, removerTagsHTML } from "../../utils/functions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faInstagram, faTwitter, faYoutube, faWhatsapp, faLinkedin, faGoogle } from "@fortawesome/free-brands-svg-icons";
import Header from "../features/Header";
import AdSenseAd from "../features/AdSense";

interface ARadioData {
    descricao: string;
    telefones: string;
    email: string;
    endereco: string;
    numero: string;
    bairro: string;
    cidade: string;
    estado: string;
    cep: string;
    link_facebook: string;
    link_instagram: string;
    link_twitter: string;
    link_youtube: string;
    link_whatsapp: string;
    link_linkedin: string;
    link_google: string;
    link: string;
    titulo: string;
    link_admin: string;
    logo: string;
}

const HomePage: React.FC = () => {
    const [configuracoes, setConfiguracoes] = useState<ARadioData>();

    useEffect(() => {
        async function fetchData() {
            const configuracoes = await fetchConfiguracoes();
            setConfiguracoes(configuracoes);
        }

        fetchData();
    }, []);

    const descricaoLimitada = configuracoes?.descricao ? limitarParaParagrafos(configuracoes.descricao, 3) : "Nenhuma descrição disponível";

    return (
        <>
            <Header
                title={configuracoes?.titulo ? configuracoes?.titulo : ""}
                description={removerTagsHTML(configuracoes?.descricao ? configuracoes?.descricao : "")}
                image={`${configuracoes?.link_admin}/uploads/${configuracoes?.logo}`}
                type="website"
                type_twitter="summary"
                url=""
            />
            <Layout>
                <section className="section-noticias">
                    <div className="container">
                        <h2 className="titulos-home">Novidades</h2>
                        <Noticias paginacao={false} postPerPage={8} subtitulo={false} />
                        <div className="d-flex justify-content-center">
                            <Link className="call-buttons margin-top-20" to="/noticias">
                                Veja mais
                            </Link>
                        </div>

                        <div className="container-publicidade">
                            <AdSenseAd client="ca-pub-2737470271681040" slot="5837546738" />
                        </div>
                    </div>
                </section>

                <section className="section-programas">
                    <div className="container">
                        <h2 className="titulos-home">Programação</h2>
                        <Programas />

                        <div className="container-publicidade">
                            <AdSenseAd client="ca-pub-2737470271681040" slot="5837546738" />
                        </div>
                    </div>
                </section>

                <section className="section-sobre">
                    <div className="container">
                        <h2 className="titulos-home">- Sobre Nós</h2>
                        <div dangerouslySetInnerHTML={{ __html: descricaoLimitada }}></div>
                        <div className="d-flex justify-content-center">
                            <Link className="call-buttons margin-top-20" to="/a-radio">
                                Saiba mais
                            </Link>
                        </div>
                    </div>
                </section>

                <section className="section-contato">
                    <div className="container container-2coluns-home">
                        <div className="container-redes-home">
                            <h2 className="titulos-home">Contate-nos</h2>
                            <p>
                                <span>Telefone:</span> <a href={`tel:${configuracoes?.telefones}`}>{configuracoes?.telefones}</a>
                            </p>
                            <p>
                                <span>Email:</span> <a href={`mailto:${configuracoes?.email}`}>{configuracoes?.email}</a>
                            </p>
                            <p>
                                <span>Endereço:</span> {configuracoes?.endereco}, {configuracoes?.numero}, {configuracoes?.bairro}, {configuracoes?.cidade},{" "}
                                {configuracoes?.estado}. CEP: {configuracoes?.cep}
                            </p>
                            <p>
                                <span>Redes Sociais:</span>
                                <div className="container-redes">
                                    {configuracoes?.link_facebook && (
                                        <a
                                            href={configuracoes.link_facebook}
                                            className="icone-social-container facebook-bg"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <FontAwesomeIcon icon={faFacebook} />
                                        </a>
                                    )}
                                    {configuracoes?.link_instagram && (
                                        <a
                                            href={configuracoes.link_instagram}
                                            className="icone-social-container instagram-bg"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <FontAwesomeIcon icon={faInstagram} />
                                        </a>
                                    )}
                                    {configuracoes?.link_twitter && (
                                        <a
                                            href={configuracoes.link_twitter}
                                            className="icone-social-container twitter-bg"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <FontAwesomeIcon icon={faTwitter} />
                                        </a>
                                    )}
                                    {configuracoes?.link_youtube && (
                                        <a
                                            href={configuracoes.link_youtube}
                                            className="icone-social-container youtube-bg"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <FontAwesomeIcon icon={faYoutube} />
                                        </a>
                                    )}
                                    {configuracoes?.link_whatsapp && (
                                        <a
                                            href={configuracoes.link_whatsapp}
                                            className="icone-social-container whatsapp-bg"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <FontAwesomeIcon icon={faWhatsapp} />
                                        </a>
                                    )}
                                    {configuracoes?.link_linkedin && (
                                        <a
                                            href={configuracoes.link_linkedin}
                                            className="icone-social-container linkedin-bg"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <FontAwesomeIcon icon={faLinkedin} />
                                        </a>
                                    )}
                                    {configuracoes?.link_google && (
                                        <a
                                            href={configuracoes.link_google}
                                            className="icone-social-container google-bg"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <FontAwesomeIcon icon={faGoogle} />
                                        </a>
                                    )}
                                </div>
                            </p>
                        </div>
                        <div className="container-contato-home">
                            <h2 className="titulos-home">- Envie sua mensagem</h2>
                            <Contato />
                        </div>
                    </div>
                </section>
            </Layout>
        </>
    );
};

export default HomePage;
