import React from "react";
import Layout from "./../Layout";
import Programas from "../features/Programas";
import Header from "../features/Header";

const ProgramacaoPage: React.FC = () => {

    
    return (
        <>
            <Header
                title="Programação"
                description="Confira a programação da rádio. Veja os horários dos programas e não perca nenhum deles."
                image=""
                type="website"
                type_twitter="summary"
                url={"programacao"}
            />
            <Layout>
                <div className="container">
                    <div className="content-container">
                        <h1>Programação</h1>
                        <Programas />
                    </div>
                </div>
            </Layout>
        </>
    );
};

export default ProgramacaoPage;
