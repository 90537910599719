import React, { createContext, useContext, ReactNode, useState, useCallback } from "react";
import ReactPlayer from "react-player";

interface AudioContextProps {
    children: ReactNode;
    audioUrl: string;
}

interface AudioContextValue {
    isPlaying: boolean;
    togglePlay: () => void;
    audioUrl: string;
    volume: number;
    setVolume: (volume: number) => void;
}

const AudioContext = createContext<AudioContextValue | undefined>(undefined);

export function useAudio() {
    const context = useContext(AudioContext);
    if (!context) {
        throw new Error("useAudio deve ser usado dentro de um provedor de áudio.");
    }
    return context;
}

export function AudioProvider({ children, audioUrl }: AudioContextProps) {
    const [isPlaying, setIsPlaying] = useState(false);
    const [volume, setVolume] = useState(1); // Inicialize o volume com 1 (valor máximo)

    const togglePlay = () => {
        setIsPlaying((prevIsPlaying) => !prevIsPlaying);
    };

    const updateVolume = useCallback((newVolume: number) => {
        setVolume(newVolume);
    }, []);

    return (
        <AudioContext.Provider value={{ isPlaying, togglePlay, audioUrl, volume, setVolume: updateVolume }}>
            {children}
            <ReactPlayer
                url={audioUrl}
                playing={isPlaying}
                volume={volume}
                controls={false}
                width="0"
                height="0"
                onEnded={() => {
                    // Lida com o término da reprodução, se necessário
                }}
            />
        </AudioContext.Provider>
    );
}
