import React, { ReactNode } from "react";
import { useLocation } from "react-router-dom"; // Importe useLocation

import Header from "./Header";
import "./../assets/css/styles.css";
import SlideShow from "./features/SlideShow";
import AudioPlayer from "./features/AudioPlayer";
import Footer from "./Footer";

interface LayoutProps {
    children: ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
    const location = useLocation(); // Obtenha a localização atual da rota

    return (
        <div>
            <Header />
            {location.pathname === "/" && <SlideShow />}
            {children}
            <Footer />
            <AudioPlayer />
        </div>
    );
};

export default Layout;
