import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import HomePage from "./components/pages/HomePage";
import NoticiasPage from "./components/pages/NoticiasPage";
import ProgramacaoPage from "./components/pages/ProgramacaoPage";
import EquipePage from "./components/pages/EquipePage";
import SeuRecadoPage from "./components/pages/SeuRecadoPage";
import ARadioPage from "./components/pages/ARadioPage";
import ContatoPage from "./components/pages/ContatoPage";
import NoticiaPageInterna from "./components/pages/NoticiaPageInterna";
import { AudioProvider } from "./components/features/AudioContext";

const App: React.FC = () => {
    const audioUrl = "https://player.srvstm.com/proxy/7066";
    return (
        <BrowserRouter>
            <AudioProvider audioUrl={audioUrl}>
                <Routes>
                    <Route path="/" element={<HomePage />} />
                    <Route path="/noticias" element={<NoticiasPage />} />
                    <Route path="/programacao" element={<ProgramacaoPage />} />
                    <Route path="/equipe" element={<EquipePage />} />
                    <Route path="/seu-recado" element={<SeuRecadoPage />} />
                    <Route path="/a-radio" element={<ARadioPage />} />
                    <Route path="/contato" element={<ContatoPage />} />
                    <Route path="/noticias/:slug" element={<NoticiaPageInterna />} />
                </Routes>
            </AudioProvider>
        </BrowserRouter>
    );
};

export default App;
