import React from "react";
import Layout from "./../Layout";
import Contato from "../features/Contato";
import "./../../assets/css/contato.css";
import Header from "../features/Header";

const ConatoPage: React.FC = () => {
    return (
        <>
            <Header
                title="Contato"
                description="Envie uma mensagem e entraremos em contato com você."
                image=""
                type="website"
                type_twitter="summary"
                url={"contato"}
            />
            <Layout>
                <div className="container">
                    <div className="content-container">
                        <h1>Contato</h1>
                        <Contato />
                    </div>
                </div>
            </Layout>
        </>
    );
};

export default ConatoPage;
