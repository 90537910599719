import React, { useEffect, useState } from "react";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import "./../../assets/css/paginacao.css";
import "./../../assets/css/contato.css";

const Contato = () => {
    // Estados para armazenar os valores do formulário
    const [formData, setFormData] = useState({
        nome: "",
        telefone: "",
        email: "",
        mensagem: "",
    });
    // Função para lidar com o envio do formulário
    const handleSubmit = async (event: any) => {
        event.preventDefault();
        try {
            // Fazer uma solicitação POST para o destino
            const response = await axios.post(`https://www.89fmonline.com.br/api/contato/api`, formData);

            // Verificar a resposta do servidor
            if (response.status === 200) {
                // A solicitação foi bem-sucedida
                toast.success("Mensagem enviada com sucesso!", {
                    position: "bottom-center",
                    autoClose: false,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                console.log("Dados enviados com sucesso!");
            } else {
                // Tratar erros ou feedback do servidor, se necessário
                toast.error("Erro ao enviar dados!", {
                    position: "bottom-center",
                    autoClose: false,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                console.error("Erro ao enviar dados:", response.data);
            }
        } catch (error) {
            // Tratar erros de rede ou outros erros
            console.error("Erro ao enviar dados:", error);
        }
    };

    // Função para lidar com a mudança nos campos do formulário
    const handleInputChange = (event: any) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };

    return (
        <>
            <ToastContainer position="bottom-center" autoClose={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable theme="light" />
            <div className="contato-container">
                <form onSubmit={handleSubmit}>
                    <div>
                        <label htmlFor="nome">Nome *:</label>
                        <input type="text" id="nome" name="nome" value={formData.nome} onChange={handleInputChange} required />
                    </div>
                    <div>
                        <label htmlFor="telefone">Telefone:</label>
                        <input type="tel" id="telefone" name="telefone" value={formData.telefone} onChange={handleInputChange} />
                    </div>
                    <div>
                        <label htmlFor="email">Email *:</label>
                        <input type="email" id="email" name="email" value={formData.email} onChange={handleInputChange} required />
                    </div>
                    <div>
                        <label htmlFor="mensagem">Mensagem *:</label>
                        <textarea id="mensagem" name="mensagem" value={formData.mensagem} onChange={handleInputChange} required />
                    </div>
                    <div className="form-footer">
                        <button type="submit">Enviar</button>
                    </div>
                </form>
            </div>
        </>
    );
};

export default Contato;
