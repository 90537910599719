export function limitarParaParagrafos(texto: string | undefined, limiteParagrafos: number) {
    if (typeof texto === "string" && texto.trim() !== "") {
        // Remover tags HTML, exceto as tags de parágrafo <p>
        const textoComTagsDeParagrafo = texto.replace(/<(?!\/?p(?=>|\s.*>))\/?.*?>/g, "");

        // Dividir o texto em parágrafos
        const paragrafos = textoComTagsDeParagrafo.split("<p");

        // Pegar até 'limiteParagrafos' parágrafos
        const paragrafosLimitados = paragrafos.slice(0, limiteParagrafos);

        // Juntar os parágrafos de volta em um texto
        return paragrafosLimitados.join("<p");
    }
    return ""; // Retorna uma string vazia se não for uma string ou for uma string vazia
}

export function removerTagsHTML(texto: string) {
    return texto.replace(/<[^>]*>/g, "");
}
