import React from "react";
import Layout from "./../Layout";

const SeuRecadoPage: React.FC = () => {
    return (
        <Layout>
            {/* Conteúdo da página */}
            <h1>Seu Recado</h1>
            <p>Seu Recado...</p>
        </Layout>
    );
};

export default SeuRecadoPage;
