import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { fetchNoticias } from "../../utils/dados";
import { Link } from "react-router-dom";
import moment from "moment";

import "./../../assets/css/paginacao.css";
import "./../../assets/css/noticias.css";

interface NewsData {
    id: number;
    slug: string;
    titulo: string;
    frase_destaque: string;
    imagem_destaque: string;
    data: string;
    categoria: string;
}

interface NewsProps {
    paginacao: boolean;
    subtitulo: boolean;
    postPerPage?: any;
}

const Noticias = ({ paginacao, postPerPage, subtitulo }: NewsProps) => {
    const [newsData, setNewsData] = useState<NewsData[]>([]);
    const [totalData, setTotalData] = useState(0);
    const [pageNumber, setPageNumber] = useState(0);

    useEffect(() => {
        async function fetchData() {
            const noticias = await fetchNoticias(pageNumber, postPerPage);
            setNewsData(noticias.noticias);
            setTotalData(noticias.total.total);
        }
        fetchData();
    }, [pageNumber]);

    const pageCount = Math.ceil(totalData / postPerPage);

    const handlePageChange = ({ selected }: { selected: number }) => {
        setPageNumber(selected);
    };

    return (
        <div>
            <div className="news-container">
                {newsData.map((news) => (
                    <div key={news.id} className="news-card">
                        <Link className="news-card-link" key={news.id} to={`/noticias/${news.slug}`}></Link>
                        <div className="card-image" style={{ backgroundImage: `url(${news.imagem_destaque[0]})` }}></div>
                        <span className="news-info">
                            {/* Data */}
                            {news.data ? moment(news.data, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY") : ""} - {news.categoria}
                        </span>
                        <h3 className="news-title">{news.titulo}</h3>
                        {subtitulo && <p>{news.frase_destaque}</p>}
                    </div>
                ))}
            </div>
            {paginacao && (
                <ReactPaginate
                    previousLabel={"Anterior"}
                    nextLabel={"Próxima"}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={pageCount}
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={window.innerWidth <= 768 ? 1 : 3}
                    onPageChange={handlePageChange}
                    containerClassName={"pagination"}
                    activeClassName={"active"}
                />
            )}
        </div>
    );
};

export default Noticias;
