import React, { useState } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";
import { Link, useLocation } from "react-router-dom";
import { isRouteActive } from "./../utils/routeUtils";

const Nav = styled.nav`
    ul {
        list-style: none;
        padding: 0;
        display: flex;
        gap: 20px;
        z-index: 1;
    }

    ul li {
        font-size: 18px;
        transition: all 0.5s ease-in;
        display: flex;
    }

    ul li a {
        color: #fff;
        text-decoration: none;
        text-transform: uppercase;
        padding: 15px;
        font-size: 0.9rem;
        font-weight: bold;
    }

    ul li:hover {
        border-bottom: solid 3px #fea259;
        transition: all 0.5s ease-in;
    }

    ul li.active {
        border-bottom: solid 3px #fea259;
    }

    ul li.active a {
        color: #fea259 !important;
    }

    .menu-icon {
        display: none;
        cursor: pointer;
        font-size: 24px;
        padding: 15px;
    }

    &.open ul {
        transform: translateX(0); /* Quando o menu estiver aberto, traga-o de volta para a tela */
    }

    @media (max-width: 768px) {
        .menu-icon {
            display: block;
        }

        ul {
            display: flex;
            position: fixed;
            top: 0px;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: rgba(0, 0, 0, 0.9);
            flex-direction: column;
            margin: 0;
            padding: 0;
            padding-top: 20px;
            transition: transform 0.3s ease-in-out; /* Adicione uma transição para o efeito de deslizamento */
            transform: translateX(100%); /* Inicialmente, o menu está fora da tela */
            z-index: 2;
        }

        &.open ul {
            display: flex;
            align-items: center; /* Alinhe os itens verticalmente */
            justify-content: start; /* Centralize os itens horizontalmente */
        }

        ul li a {
            color: #fff;
        }

        ul li.active {
            border-bottom: solid 3px #fea259;
        }

        ul li.active a {
            color: #fea259;
        }
    }
`;

const Navbar = () => {
    const location = useLocation();

    const isActive = (route: any) => {
        return isRouteActive(location.pathname, route);
    };

    const [menuOpen, setMenuOpen] = useState(false);

    const toggleNav = () => {
        setMenuOpen(!menuOpen);
    };

    return (
        <Nav className={menuOpen ? "open" : ""}>
            <FontAwesomeIcon
                className={`menu-icon ${menuOpen ? "fa-times" : "fa-bars"}`}
                icon={menuOpen ? faTimes : faBars} // Use the imported icons here
                onClick={toggleNav}
            />
            <ul onClick={toggleNav}>
                <li className={isActive("/") ? "active" : ""}>
                    <Link to="/">Home</Link>
                </li>
                <li className={isActive("/programacao") ? "active" : ""}>
                    <Link to="/programacao">Programação</Link>
                </li>
                <li className={isActive("/noticias") ? "active" : ""}>
                    <Link to="/noticias">Notícias</Link>
                </li>
                <li className={isActive("/a-radio") ? "active" : ""}>
                    <Link to="/a-radio">A Rádio</Link>
                </li>
                <li className={isActive("/contato") ? "active" : ""}>
                    <Link to="/contato">Contato</Link>
                </li>
            </ul>
        </Nav>
    );
};

export default Navbar;
